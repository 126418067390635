import {CreateControllerFn} from '@wix/yoshi-flow-editor'
import {createEventHandler} from '@wix/tpa-settings'

import {isTemplateView} from '../../utils/flowAPI'
import {SettingsEvents, SettingsEventsValues} from '../../utils/constants'
import {generateBreadcrumbsSD} from '../../utils/getBreadcrumbsConfig'

import {initReservationConfirmationStorage, mockReservationConfirmationStorage} from './storage'

const createController: CreateControllerFn = async ({flowAPI, controllerConfig}) => {
  const publicData = controllerConfig.config.publicData.COMPONENT || {}
  const handler = createEventHandler<SettingsEventsValues>(publicData)
  const siteStructure = await controllerConfig.wixCodeApi.site.getSiteStructure({
    includePageId: true,
  })
  return {
    async pageReady() {
      const isEditor = flowAPI.environment.isEditor
      const isPreview = flowAPI.environment.isPreview
      const isTemplate = isTemplateView(flowAPI)

      handler.on(SettingsEvents.APPROVAL_TEXT_SETTINGS, (value) => {
        flowAPI.controllerConfig.setProps({approvalTextEditorState: value})
      })

      if (isEditor || isPreview || isTemplate) {
        const mockedStorage = await mockReservationConfirmationStorage(flowAPI, siteStructure)
        flowAPI.controllerConfig.setProps(mockedStorage)
      } else {
        const storage = initReservationConfirmationStorage(flowAPI, siteStructure)
        flowAPI.controllerConfig.setProps(storage)

        const breadCrumbsSD = generateBreadcrumbsSD(storage.breadcrumbsConfig)
        flowAPI.controllerConfig.wixCodeApi.seo.setStructuredData([breadCrumbsSD])

        if (storage.reservationId) {
          storage.getReservationLocations()
          storage.getReservation(storage.reservationId)
        }

        if (storage.orderId) {
          await storage.getOrderDetails(storage.orderId)
        }
      }
    },
    updateConfig(_, config) {
      handler.notify(config.publicData.COMPONENT || {})
    },
  }
}

export default createController
